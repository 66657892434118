.popup {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0,0,0, 0.5);
}
.popup_inner {
  position: absolute;
  left: 5%;
  right: 5%;
  top: 35%;
  bottom: 25%;
  margin: auto;
  background: white;
  border: 2px solid #6E6D6B;
  border-radius: 10px;
  min-width: 330px;
  min-height: 450px;
}

.BigLogo {
  width: 60%;
  height: auto;
  position: absolute; 
  left: 50%;
  transform: translate(-50%);
  padding-top: 15px;
  display: inline-block; 
  text-align: center;
}

.MainPU {
  margin-top: 225px;
  margin-bottom: 20px;
  font-size: 14px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Open Sans', sans-serif;
}

.PUP {
  text-align: left;
}

.ClosePU {
  border: 2px solid #6E6D6B;
  border-radius: 7px;
  background-color: white;
  font-size: 15px;
  font-family: 'Open Sans', sans-serif;
}

@media screen and (min-width: 400px) {
  .popup_inner {
    min-width: 330px;
    max-width: 400px;
    height: 500px;
  }
  
  .BigLogo {
    width: 250px;
  }
  
  .MainPU {
    margin-top: 280px;
  }  
}