.App {
  font-family: "Open Sans",
  sans-serif; 
  font-size: 20px;
  color: #444;
  text-align: center;
  line-height: 1.5;
  position: relative;
  height: 100%;
  min-height: 90vh;
  padding-bottom: 66px;
  min-width: 373px;
  background-color: #EEEDE9;
  background-image: url('../images/MtH.JPG');
  background-position: right;
  background-size: cover;
  background-repeat: no-repeat;
}

.App:after {
  opacity: 0.6;
}

.App__nav {
  padding-top: 5px;
  /* background-color: #EEEDE9; */
  background-color: #706f6d;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  min-width: 373px;
  margin-bottom: 2.5rem;
  border-bottom-width: 1px;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);
}

.hrHead {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  border: none;
  margin-top: 1px;
  margin-bottom: 0px;
  background-color: #EEEDE9;
}

.hrFoot {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  border: 1px solid black;
  margin-top: 0px;
  margin-bottom: 0px;
  background-color: #EEEDE9;
}

.logo {
  width: 100px;
  position: absolute; 
  left: 50%;
  transform: translate(-145%);
  padding-top: 3px;
  display: inline-block; 
  text-align: center;
}

.logoTitle {
  text-decoration: none;
  display: inline-block; 
  left: 50%;
  transform: translate(30%);
  color: white;
  font-size: 45px;
  /* font-family: 'Libre Baskerville', serif; */
  /* font-family: 'Saira Stencil One', cursive; */
  font-family: 'Oswald', sans-serif;
  letter-spacing: 5px;
  /* margin: 20px; */
  margin-top: 15px;
  margin-bottom: 25px;
}

.Menu {
  margin-top: 50px;
  margin-bottom: 50px;
}


.App__footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: #706f6d;
  /* margin-top: 40px; */
  padding-top: 3px;
  padding-bottom: 1px;
  font-size: 15px;
  font-weight: bold;
  border-top-width: 1px;
  box-shadow: 0 -4px 6px -1px rgba(0,0,0,.1), 0 -2px 4px -1px rgba(0,0,0,.06);
  font-family: 'Open Sans', sans-serif;
}


.footerText {
  font-size: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
}

@media screen and (min-width: 768px) {
  .logo {
    left: 50%;
    transform: translate(-165%);
  }
  .logoTitle {
    left: 50%;
    transform: translate(20%);
  }
  .Menu {
    margin-top: 10px;
    margin-bottom: 110px;
  }
  .footerText {
    font-size: 13px;
    padding-left: 5px;
    padding-right: 5px;
  }  
}