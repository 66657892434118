.Menu {
  display: grid;
  justify-content: space-evenly;
  grid-template-columns: auto auto auto;
  max-width: 300px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
}

.Menu__div {
  margin-top: 100px;
  padding-top: 20px;
  padding-left: 20px;
  padding-right: 20px;
}

.trialDL {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  border-radius: 17px;
  font-size: 14px;
  width: 100px;
  height: 100px;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,.1), 0 2px 4px -1px rgba(0,0,0,.06);
}

.DLImg{
  width:100px;
  background-color: white;
  border-radius: 17px;
}

@media screen and (max-width: 768px) {
  .Menu {
    display: grid;
    justify-content: space-evenly;
    grid-column-gap: 10px;
    grid-template-columns: auto auto auto;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }  
}

@media screen and (min-width: 768px) {
  .Menu {
    display: grid;
    justify-content: space-evenly;
    grid-template-columns: auto auto auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;
  }
    
  .trialDL {
    margin-top: 50px;
    width: 200px;
    height: 200px;
    box-shadow: 0 6px 6px -1px rgba(0,0,0,.1), 0 4px 4px -1px rgba(0,0,0,.06);
  }  
    
  .Menu {
    margin-bottom: 100px;
  }

  .DLImg{
    width:200px;
    background-color: white;
  }
}