.PDFComp {
  background-color: #EEEDE9;
  margin-top: 115px;
  padding-bottom: 60px;

}

.PDImgDL {
  padding-top: 20px;
  padding-left: 50px;
  padding-right: 50px;
  font-family: 'Open Sans', sans-serif;
}

/* .imgBorder {
  padding-bottom: 80px;
  background-color: #EEEDE9;

} */

.PDImg {
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 10px;
  /* height:50vh; */
}

.imgWidth {
  width: 100%;
}

.PDDL {
  color: black;
}

@media screen and (min-width: 1000px) {
  /* .PDImg {
    margin-left: 200px;
    margin-right: 200px;
  } */
  .imgWidth {
    width: 50%;
  }
  
}